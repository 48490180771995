<template>
  <div class="relative flex justify-center items-center min-h-screen p-4 bg-black overflow-hidden">
    <div class="absolute inset-0 z-0 pointer-events-none">
      <canvas id="particles"></canvas>
    </div>

    <div class="absolute inset-0 bg-gradient-to-br from-black via-purple-900 to-purple-800 opacity-80"></div>

    <div
      class="relative z-10 p-8 border border-transparent rounded-3xl shadow-lg w-full max-w-md backdrop-blur-lg bg-transparent group">
      <div
        class="absolute inset-0 rounded-3xl border-[3px] border-purple-500 shadow-[inset_0_0_8px_rgba(255,255,255,0.2),_0_4px_6px_rgba(0,0,0,0.4)] pointer-events-none">
      </div>

      <div class="text-center mb-10">
        <img src="@/assets/logo.png" alt="Company Logo" class="mx-auto h-20 w-auto">
        <h1 class="text-3xl font-extrabold text-purple-400 mt-6 tracking-wide glow">Bem-vindo(a) ao ControlEase</h1>
        <p class="text-sm text-gray-300">Faça login para acessar sua conta</p>
      </div>

      <form @submit.prevent="login" class="space-y-6">
        <div>
          <label for="username" class="block text-sm font-medium text-gray-300 mb-2">Usuário</label>
          <input id="username" v-model="username" type="text" placeholder="Digite seu usuário" required
            class="w-full px-4 py-3 bg-transparent border border-gray-600 rounded-lg text-white text-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent transition-all duration-300 ease-in-out glow-input" />
        </div>

        <div class="relative">
          <label for="password" class="block text-sm font-medium text-gray-300 mb-2">Senha</label>
          <div class="relative">
            <input id="password" :type="showPassword ? 'text' : 'password'" v-model="password"
              placeholder="Digite sua senha" required
              class="w-full px-4 py-3 pr-12 bg-transparent border border-gray-600 rounded-lg text-white text-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent transition-all duration-300 ease-in-out glow-input" />
            <font-awesome-icon :icon="showPassword ? 'eye-slash' : 'eye'"
              class="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-400 cursor-pointer hover:text-gray-200 transition text-lg"
              @click="togglePassword" />
          </div>
        </div>


        <button type="submit" :disabled="isLoading"
          class="w-full flex items-center justify-center bg-gradient-to-r from-purple-600 to-purple-800 hover:from-purple-500 hover:to-purple-700 text-white font-semibold py-3 rounded-lg shadow-lg transition-transform duration-300 ease-in-out transform hover:scale-105 hover:shadow-neon disabled:opacity-50 disabled:cursor-not-allowed">
          <span v-if="isLoading" class="loader mr-2"></span>
          <span>{{ isLoading ? 'Entrando...' : 'Entrar' }}</span>
        </button>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import authService from '@/service/authService';
import { useRouter } from 'vue-router';

const username = ref('');
const password = ref('');
const showPassword = ref(false);
const isLoading = ref(false);
const router = useRouter();

const togglePassword = () => {
  showPassword.value = !showPassword.value;
};

const login = async () => {
  isLoading.value = true;

  try {
    const result = await authService.login({
      username: username.value,
      password: password.value,
    });

    if (result.success) {
      router.push('/user/dashboard');
    } else {
      alert('Falha no login: ' + result.message);
    }
  } catch (error) {
    alert('Erro ao conectar ao servidor.');
  } finally {
    isLoading.value = false;
  }
};

const initParticles = () => {
  const canvas = document.getElementById('particles');
  const ctx = canvas.getContext('2d');

  canvas.width = window.innerWidth;
  canvas.height = window.innerHeight;

  let particles = Array(100).fill().map(() => ({
    x: Math.random() * canvas.width,
    y: Math.random() * canvas.height,
    radius: Math.random() * 3 + 1,
    dx: Math.random() * 2 - 1,
    dy: Math.random() * 2 - 1,
  }));

  const animate = () => {
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    particles.forEach((particle) => {
      ctx.beginPath();
      ctx.arc(particle.x, particle.y, particle.radius, 0, Math.PI * 2);
      ctx.fillStyle = 'rgba(255, 255, 255, 0.7)';
      ctx.fill();
      particle.x += particle.dx;
      particle.y += particle.dy;

      if (particle.x < 0 || particle.x > canvas.width) particle.dx *= -1;
      if (particle.y < 0 || particle.y > canvas.height) particle.dy *= -1;
    });

    requestAnimationFrame(animate);
  };

  animate();
};

onMounted(() => {
  initParticles();
});
</script>

<style scoped>
body {
  font-family: 'Inter', sans-serif;
}

.glow {
  text-shadow: 0 0 8px rgba(128, 0, 128, 0.8), 0 0 15px rgba(128, 0, 128, 0.6);
}

.glow-input:focus {
  box-shadow: 0 0 5px rgba(128, 0, 128, 0.5), 0 0 10px rgba(128, 0, 128, 0.3);
}

.hover\:shadow-neon:hover {
  box-shadow: 0 0 10px rgba(128, 0, 255, 0.8), 0 0 20px rgba(128, 0, 255, 0.6);
}

.loader {
  width: 18px;
  height: 18px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-top-color: #fff;
  border-radius: 50%;
  animation: spin 0.6s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
