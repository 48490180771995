import axios from 'axios';

const API_URL = 'https://web-production-7b75.up.railway.app/api/auth/';

class AuthService {
    async login(user) {
        try {
            const response = await axios.post(API_URL + 'login', {
                username: user.username,
                password: user.password,
            });

            if (response.data.token) {
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('usuario_id', response.data.usuario_id);
                return { success: true, token: response.data.token, usuario_id: response.data.usuario_id };
            }

            return { success: false, message: 'Login falhou' };
        } catch (error) {
            console.error('Erro ao fazer login:', error);
            return {
                success: false,
                message: error.response?.data?.message || 'Erro desconhecido ao fazer login',
            };
        }
    }

    logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('usuario_id');
    }

    getCurrentUser() {
        const token = localStorage.getItem('token');
        const usuario_id = localStorage.getItem('usuario_id');
        return token && usuario_id ? { token, usuario_id } : null;
    }
}

export default new AuthService();
