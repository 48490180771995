<template>
  <div class="calendar max-w-full sm:max-w-4xl mx-auto p-4 sm:p-6 bg-white rounded-lg shadow-lg">
    <h1
      class="block w-full text-center text-2xl sm:text-3xl md:text-4xl font-bold leading-tight shadow-lg p-3 rounded-lg bg-gradient-to-r from-purple-500 to-purple-500 text-transparent bg-clip-text">
      Calendário de Gastos e Tarefas
    </h1>
    <br />
    <div class="flex flex-col sm:flex-row justify-between items-center mb-6">
      <button @click="changeMonth(-1)"
        class="text-purple-500 hover:bg-purple-100 p-2 rounded-lg transition-all duration-300">
        <font-awesome-icon icon="chevron-left" class="text-sm" />
      </button>
      <h3 class="text-sm font-semibold text-gray-600">{{ monthNames[currentMonth] }} {{ currentYear }}</h3>
      <button @click="changeMonth(1)"
        class="text-purple-500 hover:bg-purple-100 p-2 rounded-lg transition-all duration-300">
        <font-awesome-icon icon="chevron-right" class="text-sm" />
      </button>
    </div>

    <div class="grid grid-cols-7 gap-2 mb-6 text-center font-bold text-gray-700">
      <div v-for="(day, index) in weekDays" :key="index" class="text-xs">{{ day }}</div>
    </div>

    <div class="grid grid-cols-7 gap-2 text-center">
      <div v-for="emptyDay in firstDayOfMonth" :key="emptyDay" class="calendar-cell"></div>
      <div v-for="day in daysInMonth" :key="day"
        class="calendar-cell relative py-4 bg-white rounded-lg shadow-sm cursor-pointer border border-gray-200 hover:bg-purple-50 transition-all duration-300"
        @click="selectDate(day)" :class="{ 'bg-purple-50': isCurrentDay(day) }">
        <span class="block text-xs font-semibold">{{ day }}</span>
        <div v-if="getGastosByDate(day).length || getTarefasByDate(day).length" class="text-[10px] text-gray-600 mt-2">
          <div class="flex justify-center space-x-1 sm:hidden">
            <span v-if="getGastosByDate(day).length" class="text-[8px] text-white bg-stone-700 p-1 rounded-sm">G</span>
            <span v-if="getTarefasByDate(day).length"
              class="text-[8px] text-white bg-purple-700 p-1 rounded-sm">T</span>
          </div>
          <span v-if="getGastosByDate(day).length"
            class="hidden sm:block text-[9px] text-white bg-stone-700 p-1 rounded-sm">
            Gastos: R$ {{ getTotalGastosByDate(day).toFixed(2) }}
          </span>
          <span v-if="getTarefasByDate(day).length"
            class="hidden sm:block text-[9px] text-white bg-purple-700 p-1 rounded-sm">
            Tarefas: {{ getTarefasByDate(day).length }}
          </span>
        </div>
      </div>
    </div>

    <div v-if="selectedGastos.length || selectedTarefas.length" class="mt-8">
      <h3 class="text-xs font-semibold text-center text-gray-800 mb-4">
        Itens do Dia {{ selectedDate }}/{{ currentMonth + 1 }}/{{ currentYear }}
      </h3>
      <div class="space-y-4">
        <div v-for="gasto in selectedGastos" :key="gasto.id"
          class="bg-white rounded-lg p-4 shadow-md border-l-4 border-purple-500">
          <div class="flex items-center justify-between">
            <div class="flex items-center space-x-2">
              <font-awesome-icon icon="file-alt" class="text-purple-500" />
              <span class="font-semibold text-xs text-gray-700">{{ gasto.descricao }}</span>
            </div>
            <span class="text-green-500 font-semibold text-xs">R$ {{ gasto.valor.toFixed(2) }}</span>
          </div>
          <p class="text-[10px] text-gray-500 mt-2">Status: <span :class="getStatusClass(gasto)">{{ gasto.status
              }}</span></p>
        </div>

        <div v-for="tarefa in selectedTarefas" :key="tarefa.id"
          class="bg-white rounded-lg p-4 shadow-md border-l-4 border-green-500">
          <div class="flex items-center justify-between">
            <div class="flex items-center space-x-2">
              <font-awesome-icon icon="tasks" class="text-green-500" />
              <span class="font-semibold text-xs text-gray-700">{{ tarefa.nome }}</span>
            </div>
          </div>
          <p class="text-[10px] text-gray-500 mt-2">{{ tarefa.descricao }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import axios from 'axios';

const gastos = ref([]);
const tarefas = ref([]);

const currentDate = new Date();
const currentYear = ref(currentDate.getFullYear());
const currentMonth = ref(currentDate.getMonth());
const selectedDate = ref(null);
const selectedGastos = ref([]);
const selectedTarefas = ref([]);

const weekDays = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];
const monthNames = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

const daysInMonth = computed(() => {
  return new Date(currentYear.value, currentMonth.value + 1, 0).getDate();
});

const firstDayOfMonth = computed(() => {
  return new Date(currentYear.value, currentMonth.value, 1).getDay();
});

const changeMonth = (direction) => {
  currentMonth.value += direction;
  if (currentMonth.value < 0) {
    currentMonth.value = 11;
    currentYear.value -= 1;
  } else if (currentMonth.value > 11) {
    currentMonth.value = 0;
    currentYear.value += 1;
  }
  selectedDate.value = null;
  selectedGastos.value = [];
  selectedTarefas.value = [];
  fetchGastos();
  fetchTarefas();
};

const selectDate = (day) => {
  selectedDate.value = day;
  selectedGastos.value = getGastosByDate(day);
  selectedTarefas.value = getTarefasByDate(day);
};

const getGastosByDate = (day) => {
  const formattedDate = `${currentYear.value}-${String(currentMonth.value + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
  return gastos.value.filter(gasto => gasto.dataPagamento === formattedDate);
};

const getTarefasByDate = (day) => {
  const formattedDate = `${currentYear.value}-${String(currentMonth.value + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
  return tarefas.value.filter(tarefa => tarefa.data === formattedDate);
};

const getTotalGastosByDate = (day) => {
  return getGastosByDate(day).reduce((total, gasto) => total + gasto.valor, 0);
};

const getStatusClass = (gasto) => {
  return gasto.status === 'PAGO' ? 'text-green-500' : 'text-red-500';
};

const fetchGastos = async () => {
  try {
    const response = await axios.get('https://web-production-7b75.up.railway.app/gastos', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    gastos.value = response.data;
  } catch (error) {
    console.error('Erro ao buscar gastos:', error);
  }
};

const fetchTarefas = async () => {
  try {
    const response = await axios.get('https://web-production-7b75.up.railway.app/tarefas', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    tarefas.value = response.data;
  } catch (error) {
    console.error('Erro ao buscar tarefas:', error);
  }
};

const isCurrentDay = (day) => {
  const today = new Date();
  return (
      currentYear.value === today.getFullYear() &&
      currentMonth.value === today.getMonth() &&
      day === today.getDate()
  );
};

onMounted(() => {
  fetchGastos();
  fetchTarefas();
});
</script>

<style scoped>
.calendar {
  max-width: 100%;
  background-color: #fbf9fb;
  border-radius: 10px;
}

.grid {
  display: grid;
}

.calendar-cell {
  height: 90px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

button {
  transition: all 0.3s ease;
  background-color: #f1f5f9;
  border-radius: 10px;
}

button:hover {
  background-color: #e2e8f0;
}

.hover\:bg-purple-50:hover {
  background-color: #ebf8ff;
}

.shadow-lg {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.border {
  border-color: #e2e8f0;
}

@media (max-width: 640px) {
  .grid-cols-7 {
    grid-template-columns: repeat(7, 1fr);
  }
}
@media (max-width: 640px) {
  .calendar-cell {
    height: 55px;
    width: 100%;
  }
}
</style>
