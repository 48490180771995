<template>
  <div class="max-w-6xl mx-auto p-4 sm:p-6 bg-white rounded-lg shadow-lg">
    <h1
      class="block w-full text-center text-2xl sm:text-3xl md:text-4xl font-bold leading-tight shadow-lg p-3 rounded-lg bg-gradient-to-r from-purple-500 to-purple-500 text-transparent bg-clip-text">
      Listagem de Tarefas
    </h1>
    <br />
    <div class="flex flex-col sm:flex-row justify-between mb-4 sm:mb-6 space-y-4 sm:space-y-0">
      <button @click="showAddTarefaModal"
        class="bg-purple-500 text-white py-2 px-4 sm:px-6 rounded-lg hover:bg-purple-600 transition-all duration-300 shadow-sm text-sm sm:text-base">
        Adicionar Tarefa
      </button>

      <div class="flex items-center space-x-4">
        <div>
          <label for="filterAno" class="text-sm font-semibold text-gray-600 mr-2">Filtrar por Ano:</label>
          <select id="filterAno" v-model="anoSelecionado" @change="filtrarPorMes"
            class="border border-gray-300 px-3 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent text-sm sm:text-base">
            <option value="">Todos os anos</option>
            <option v-for="ano in anosDisponiveis" :key="ano" :value="ano">{{ ano }}</option>
          </select>
        </div>

        <div>
          <label for="filterMes" class="text-sm font-semibold text-gray-600 mr-2">Filtrar por Mês:</label>
          <select id="filterMes" v-model="mesSelecionado" @change="filtrarPorMes"
            class="border border-gray-300 px-3 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent text-sm sm:text-base">
            <option value="">Todos os meses</option>
            <option v-for="mes in meses" :key="mes.valor" :value="mes.valor">{{ mes.nome }}</option>
          </select>
        </div>
      </div>
    </div>


    <div class="w-full">
      <table class="table-fixed w-full border border-gray-300 rounded-lg shadow-sm">
        <thead>
          <tr class="bg-purple-100">
            <th class="w-1/3 border px-2 py-2 text-gray-600 text-xs sm:text-base">Nome</th>
            <th class="w-1/3 border px-2 py-2 text-gray-600 text-xs sm:text-base">Descrição</th>
            <th class="w-1/3 border px-2 py-2 text-gray-600 text-xs sm:text-base">Data</th>
            <th class="w-1/5 border px-2 py-2 text-gray-600 text-xs sm:text-base">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="tarefa in sortedTarefas" :key="tarefa.id" class="hover:bg-gray-100 transition-all duration-300">
            <td class="border px-2 py-2 text-xs sm:text-sm truncate">{{ tarefa.nome }}</td>
            <td class="border px-2 py-2 text-xs sm:text-sm truncate">{{ tarefa.descricao }}</td>
            <td class="border px-2 py-2 text-xs sm:text-sm truncate">{{ formatDate(tarefa.data) }}</td>
            <td class="border px-2 py-2 text-xs sm:text-sm truncate flex justify-center space-x-2 sm:space-x-4">
              <button @click="editTarefa(tarefa)"
                class="text-blue-500 hover:text-yellow-700 transition-colors duration-300">
                <svg class="w-4 h-4 sm:w-5 sm:h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z">
                  </path>
                </svg>
              </button>
              <button @click="deleteTarefa(tarefa.id)"
                class="text-red-500 hover:text-red-700 transition-colors duration-300">
                <svg class="w-4 h-4 sm:w-5 sm:h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5-4h4a1 1 0 011 1v1H9V4a1 1 0 011-1zm-5 4h12">
                  </path>
                </svg>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <transition name="fade">
      <div v-if="showModal" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div class="bg-white rounded-lg shadow-xl p-4 sm:p-6 max-w-md w-full">
          <h3 class="text-lg sm:text-xl font-semibold mb-4 sm:mb-6 text-gray-700">{{ isEditing ? 'Editar Tarefa' :
            'Adicionar Tarefa' }}</h3>
          <form @submit.prevent="handleSubmit">
            <div class="mb-4">
              <label class="block text-sm font-semibold text-gray-600 mb-2">Nome</label>
              <input v-model="tarefa.nome" type="text" required
                class="border border-gray-300 px-3 py-2 w-full rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-sm sm:text-base"
                placeholder="Nome da Tarefa" />
            </div>
            <div class="mb-4">
              <label class="block text-sm font-semibold text-gray-600 mb-2">Descrição</label>
              <input v-model="tarefa.descricao" type="text" required
                class="border border-gray-300 px-3 py-2 w-full rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-sm sm:text-base"
                placeholder="Descrição da Tarefa" />
            </div>
            <div class="mb-4">
              <label class="block text-sm font-semibold text-gray-600 mb-2">Data</label>
              <input v-model="tarefa.data" type="date" required
                class="border border-gray-300 px-3 py-2 w-full rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-sm sm:text-base" />
            </div>
            <div class="flex justify-end space-x-2 sm:space-x-4">
              <button type="submit"
                class="bg-purple-500 text-white py-2 px-4 rounded-lg hover:bg-purple-600 transition-all duration-300 text-sm sm:text-base">
                {{ isEditing ? 'Atualizar' : 'Adicionar' }}
              </button>
              <button @click="closeModal" type="button"
                class="bg-gray-300 text-gray-700 py-2 px-4 rounded-lg hover:bg-gray-400 transition-all duration-300 text-sm sm:text-base">
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </transition>
  </div>
</template>
<script setup>
import { ref, onMounted, computed } from 'vue';
import axios from 'axios';

const tarefas = ref([]);
const tarefasFiltradas = ref([]);
const showModal = ref(false);
const isEditing = ref(false);
const tarefa = ref({
  nome: '',
  descricao: '',
  data: '',
});
const apiUrl = 'https://web-production-7b75.up.railway.app/tarefas';

const meses = [
  { nome: 'Janeiro', valor: '01' },
  { nome: 'Fevereiro', valor: '02' },
  { nome: 'Março', valor: '03' },
  { nome: 'Abril', valor: '04' },
  { nome: 'Maio', valor: '05' },
  { nome: 'Junho', valor: '06' },
  { nome: 'Julho', valor: '07' },
  { nome: 'Agosto', valor: '08' },
  { nome: 'Setembro', valor: '09' },
  { nome: 'Outubro', valor: '10' },
  { nome: 'Novembro', valor: '11' },
  { nome: 'Dezembro', valor: '12' },
];

const mesSelecionado = ref('');
const anoSelecionado = ref(''); 

const getMesAtual = () => {
  const dataAtual = new Date();
  return String(dataAtual.getMonth() + 1).padStart(2, '0');
};

const getAnoAtual = () => {
  const dataAtual = new Date();
  return String(dataAtual.getFullYear());
};

const fetchTarefas = async () => {
  try {
    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    tarefas.value = response.data;
    filtrarPorMes();
  } catch (error) {
    console.error('Erro ao buscar tarefas:', error);
  }
};

const showAddTarefaModal = () => {
  resetForm();
  showModal.value = true;
};

const handleSubmit = async () => {
  try {
    if (isEditing.value) {
      await axios.put(`${apiUrl}/${tarefa.value.id}`, tarefa.value, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
    } else {
      await axios.post(apiUrl, tarefa.value, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
    }
    fetchTarefas();
    closeModal();
  } catch (error) {
    console.error('Erro ao salvar tarefa:', error);
    alert('Erro ao salvar tarefa.');
  }
};

const editTarefa = (tarefaToEdit) => {
  tarefa.value = { ...tarefaToEdit };
  isEditing.value = true;
  showModal.value = true;
};

const deleteTarefa = async (id) => {
  if (confirm('Tem certeza que deseja excluir esta tarefa?')) {
    try {
      await axios.delete(`${apiUrl}/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      fetchTarefas();
    } catch (error) {
      console.error('Erro ao excluir tarefa:', error);
      alert('Erro ao excluir tarefa.');
    }
  }
};

const closeModal = () => {
  showModal.value = false;
};

const resetForm = () => {
  tarefa.value = {
    nome: '',
    descricao: '',
    data: '',
  };
  isEditing.value = false;
};

const filtrarPorMes = () => {
  tarefasFiltradas.value = tarefas.value.filter(tarefa => {
    const [year, month] = tarefa.data.split('-');
    const mesMatches = mesSelecionado.value === '' || month === mesSelecionado.value;
    const anoMatches = anoSelecionado.value === '' || year === anoSelecionado.value;
    return mesMatches && anoMatches;
  });
};

const formatDate = (dateString) => {
  const [year, month, day] = dateString.split('-');
  return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`;
};

const sortedTarefas = computed(() => {
  // eslint-disable-next-line
  return tarefasFiltradas.value.sort((a, b) => new Date(a.data) - new Date(b.data));
});

const anosDisponiveis = computed(() => {
  const anosSet = new Set();
  tarefas.value.forEach(tarefa => {
    const ano = tarefa.data.split('-')[0];
    anosSet.add(ano);
  });
  return Array.from(anosSet).sort();
});

onMounted(() => {
  mesSelecionado.value = getMesAtual();
  anoSelecionado.value = getAnoAtual(); 
  fetchTarefas();
});
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

table {
  border-collapse: collapse;
}

table th, table td {
  text-align: left;
}

button {
  transition: all 0.3s ease;
}
</style>
