<template>
  <div class="max-w-6xl mx-auto p-6 bg-white rounded-lg shadow-lg">
    <h1
      class="block w-full text-center text-4xl font-bold leading-tight shadow-lg p-3 rounded-lg bg-gradient-to-r from-purple-500 to-purple-500 text-transparent bg-clip-text">
      Lista de Usuários
    </h1>
    <br />
    <div class="flex justify-between mb-4">
      <button @click="showAddUserModal" class="bg-purple-500 text-white py-2 px-4 rounded hover:bg-purple-600">
        Adicionar Usuário
      </button>
    </div>
    <table class="min-w-full border border-gray-300">
      <thead>
        <tr class="bg-gray-100">
          <th class="border px-4 py-2">Nome de Usuário</th>
          <th class="border px-4 py-2">Função</th>
          <th class="border px-4 py-2">Ações</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in userList" :key="user.id">
          <td class="border px-4 py-2">{{ user.username }}</td>
          <td class="border px-4 py-2">{{ user.role }}</td>
          <td class="border px-4 py-2">
            <button @click="editUser(user)" class="text-yellow-500 hover:text-yellow-700">
              Editar
            </button>
            <button @click="deleteUser(user.id)" class="text-red-500 hover:text-red-700 ml-2">
              Excluir
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Modal para Adicionar/Editar Usuário -->
    <transition name="fade">
      <div v-if="showModal" class="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
        <div class="bg-white rounded-lg shadow-lg p-6 max-w-lg w-full">
          <h3 class="text-lg font-bold mb-4">{{ isEditing ? 'Editar Usuário' : 'Adicionar Usuário' }}</h3>
          <form @submit.prevent="handleSubmit">
            <div class="mb-4">
              <label class="block text-sm font-bold mb-2">Nome de Usuário</label>
              <input v-model="user.username" type="text" required class="border px-3 py-2 w-full"
                placeholder="Nome de Usuário" />
            </div>
            <div class="mb-4">
              <label class="block text-sm font-bold mb-2">Senha</label>
              <input v-model="user.password" type="password" required class="border px-3 py-2 w-full"
                placeholder="Senha" />
            </div>
            <div class="mb-4">
              <label class="block text-sm font-bold mb-2">Função</label>
              <select v-model="user.role" class="border px-3 py-2 w-full">
                <option value="USER">Usuário</option>
                <option value="ADMIN">Administrador</option>
              </select>
            </div>
            <div class="flex justify-end">
              <button type="submit" class="bg-purple-500 text-white py-2 px-4 rounded hover:bg-purple-600">
                {{ isEditing ? 'Atualizar' : 'Adicionar' }}
              </button>
              <button @click="closeModal" class="bg-gray-300 text-gray-700 py-2 px-4 rounded ml-2">
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import {ref, onMounted} from 'vue';
import axios from 'axios';
import AuthService from '@/service/authService';

const userList = ref([]);
const showModal = ref(false);
const isEditing = ref(false);
const user = ref({
  username: '',
  password: '',
  role: 'USER', // Valor padrão
});
const apiUrl = 'https://web-production-7b75.up.railway.app/api/users';

// Verifica se o usuário está logado
const checkAuthentication = () => {
  const token = localStorage.getItem('token');
  if (!token) {
    // Se o token não existir, redireciona para a página de login
    window.location.href = '/login';
  }
};

const fetchUsers = async () => {
  checkAuthentication(); // Verifica se o usuário está autenticado antes de buscar os usuários
  try {
    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    userList.value = response.data;
  } catch (error) {
    console.error('Erro ao buscar usuários:', error);
    if (error.response.status === 401) {
      // Se o erro for 401 (não autorizado), redireciona para a página de login
      AuthService.logout(); // Limpa o token
      window.location.href = '/login';
    }
  }
};

const showAddUserModal = () => {
  resetForm();
  showModal.value = true;
};

const handleSubmit = async () => {
  checkAuthentication(); // Verifica se o usuário está autenticado antes de enviar os dados
  try {
    const payload = {
      ...user.value,
      usuarioId: localStorage.getItem('userId'),
    };

    if (isEditing.value) {
      await axios.put(`${apiUrl}/${user.value.id}`, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      alert('Usuário atualizado com sucesso!');
    } else {
      await axios.post(apiUrl, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      alert('Usuário adicionado com sucesso!');
    }
    fetchUsers();
    closeModal();
  } catch (error) {
    console.error('Erro ao salvar usuário:', error);
    alert('Erro ao salvar usuário.');
  }
};

const editUser = (userToEdit) => {
  user.value = {...userToEdit};
  isEditing.value = true;
  showModal.value = true;
};

const deleteUser = async (id) => {
  checkAuthentication(); // Verifica se o usuário está autenticado antes de excluir o usuário
  if (confirm('Tem certeza que deseja excluir este usuário?')) {
    try {
      await axios.delete(`${apiUrl}/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      alert('Usuário excluído com sucesso!');
      fetchUsers();
    } catch (error) {
      console.error('Erro ao excluir usuário:', error);
      alert('Erro ao excluir usuário.');
    }
  }
};

const closeModal = () => {
  showModal.value = false;
};

const resetForm = () => {
  user.value = {
    username: '',
    password: '',
    role: 'USER',
  };
  isEditing.value = false;
};

// Ao montar o componente, verifica se o usuário está autenticado
onMounted(fetchUsers);
</script>


<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to
{
  opacity: 0;
}
</style>
