<template>
  <div class="flex flex-col min-h-screen bg-gray-50 text-gray-800">
    <header class="flex items-center justify-between px-6 py-4 bg-purple-200 border-b border-gray-200 shadow-sm">
      <h1 class="text-lg font-semibold flex items-center">
        <font-awesome-icon icon="chart-line" class="text-purple-600 mr-3" />
        <span>ControlEase</span>
      </h1>
      <div class="hidden md:flex space-x-6">
        <p class="text-sm">Despesas: <span class="font-semibold">R$ {{ totalDespesas.toFixed(2) }}</span></p>
        <p class="text-sm">Receita: <span class="font-semibold">R$ {{ totalReceitas.toFixed(2) }}</span></p>
        <p class="text-sm">Economia: <span class="font-semibold text-green-500">R$ {{ previsaoEconomia.toFixed(2)
            }}</span></p>
      </div>
      <nav class="flex space-x-3 items-center">
        <router-link to="/user/usuarios/:id">
          <font-awesome-icon icon="user" class="text-xl hover:text-gray-500 transition duration-300" />
        </router-link>
        <router-link to="/">
          <button @click="logout"
            class="bg-red-500 hover:bg-red-600 text-sm text-white font-semibold py-1 px-3 rounded shadow-md transition">
            <font-awesome-icon icon="sign-in-alt" class="mr-1" /> Sair
          </button>
        </router-link>
        <button @click="toggleMenu" class="md:hidden text-gray-700">
          <font-awesome-icon icon="bars" class="text-2xl" />
        </button>
      </nav>
    </header>

    <div class="flex flex-1">
      <aside @click.stop :class="[
        'fixed inset-y-0 left-0 z-50 w-64 bg-white border-r border-gray-200 py-6 px-4 flex flex-col transition-transform duration-300 md:relative md:translate-x-0',
        menuAberto ? 'translate-x-0' : '-translate-x-full'
      ]">
        <nav class="flex-1 space-y-1">
          <router-link to="/user/dashboard"
            class="flex items-center px-3 py-2 text-sm font-medium rounded-md hover:bg-purple-50 hover:text-purple-600 transition-colors"
            active-class="bg-purple-100 text-purple-600" @click="fecharMenu">
            <font-awesome-icon icon="home" class="mr-3" /> Início
          </router-link>
          <router-link to="/user/gastos"
            class="flex items-center px-3 py-2 text-sm font-medium rounded-md hover:bg-purple-50 hover:text-purple-600 transition-colors"
            active-class="bg-purple-100 text-purple-600" @click="fecharMenu">
            <font-awesome-icon icon="money-bill-wave" class="mr-3" /> Gastos
          </router-link>
          <router-link to="/user/receitas"
            class="flex items-center px-3 py-2 text-sm font-medium rounded-md hover:bg-purple-50 hover:text-purple-600 transition-colors"
            active-class="bg-purple-100 text-purple-600" @click="fecharMenu">
            <font-awesome-icon icon="money-bill-wave" class="mr-3" /> Receitas
          </router-link>
          <router-link to="/user/saldo-banco"
            class="flex items-center px-3 py-2 text-sm font-medium rounded-md hover:bg-purple-50 hover:text-purple-600 transition-colors"
            active-class="bg-purple-100 text-purple-600" @click="fecharMenu">
            <font-awesome-icon icon="credit-card" class="mr-3" /> Saldo no Banco
          </router-link>
          <router-link to="/user/dinheiro-cofre"
            class="flex items-center px-3 py-2 text-sm font-medium rounded-md hover:bg-purple-50 hover:text-purple-600 transition-colors"
            active-class="bg-purple-100 text-purple-600" @click="fecharMenu">
            <font-awesome-icon icon="piggy-bank" class="mr-3" /> Cofre
          </router-link>
          <router-link to="/user/aplicacoes"
            class="flex items-center px-3 py-2 text-sm font-medium rounded-md hover:bg-purple-50 hover:text-purple-600 transition-colors"
            active-class="bg-purple-100 text-purple-600" @click="fecharMenu">
            <font-awesome-icon icon="chart-line" class="mr-3" /> Aplicações
          </router-link>
          <router-link to="/user/saldo-bolsa"
            class="flex items-center px-3 py-2 text-sm font-medium rounded-md hover:bg-purple-50 hover:text-purple-600 transition-colors"
            active-class="bg-purple-100 text-purple-600" @click="fecharMenu">
            <font-awesome-icon icon="file-invoice-dollar" class="mr-3" /> Bolsa
          </router-link>
          <router-link to="/user/tarefas"
            class="flex items-center px-3 py-2 text-sm font-medium rounded-md hover:bg-purple-50 hover:text-purple-600 transition-colors"
            active-class="bg-purple-100 text-purple-600" @click="fecharMenu">
            <font-awesome-icon icon="tasks" class="mr-3" /> Tarefas
          </router-link>
          <router-link to="/user/calendario"
            class="flex items-center px-3 py-2 text-sm font-medium rounded-md hover:bg-purple-50 hover:text-purple-600 transition-colors"
            active-class="bg-purple-100 text-purple-600" @click="fecharMenu">
            <font-awesome-icon icon="calendar-alt" class="mr-3" /> Calendário
          </router-link>
          <router-link to="/user/relatorios"
            class="flex items-center px-3 py-2 text-sm font-medium rounded-md hover:bg-purple-50 hover:text-purple-600 transition-colors"
            active-class="bg-purple-100 text-purple-600" @click="fecharMenu">
            <font-awesome-icon icon="file-alt" class="mr-3" /> Relatórios
          </router-link>
        </nav>
      </aside>

      <main class="flex-grow p-6" @click="fecharMenuSeAberto">
        <router-view />
      </main>
    </div>

    <footer class=" bg-purple-200 border-t border-gray-200 py-4 text-center text-sm text-gray-500">
      © 2024 ControlEase. Todos os direitos reservados.
    </footer>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import axios from 'axios'
import { useRouter } from 'vue-router'

const router = useRouter()
const menuAberto = ref(false)
const totalDespesas = ref(0)
const totalReceitas = ref(0)

const toggleMenu = () => {
  menuAberto.value = !menuAberto.value
}

const fecharMenu = () => {
  menuAberto.value = false
}

const fecharMenuSeAberto = () => {
  if (menuAberto.value) {
    menuAberto.value = false
  }
}

const previsaoEconomia = computed(() => totalReceitas.value - totalDespesas.value)

const obterMesAtual = () => {
  const dataAtual = new Date()
  return String(dataAtual.getMonth() + 1).padStart(2, '0')
}

const fetchTotais = async () => {
  try {
    const despesasResponse = await axios.get('https://web-production-7b75.up.railway.app/gastos', {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    })
    const receitasResponse = await axios.get('https://web-production-7b75.up.railway.app/receitas', {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
    })
    const mesAtual = obterMesAtual()

    const despesasFiltradas = despesasResponse.data.filter(
      gasto => gasto.dataPagamento.split('-')[1] === mesAtual
    )
    const receitasFiltradas = receitasResponse.data.filter(
      receita => receita.dataRecebimento.split('-')[1] === mesAtual
    )

    totalDespesas.value = despesasFiltradas.reduce((total, gasto) => total + gasto.valor, 0)
    totalReceitas.value = receitasFiltradas.reduce((total, receita) => total + receita.valor, 0)
  } catch (error) {
    console.error('Erro ao buscar totais:', error)
  }
}

onMounted(() => {
  fetchTotais()
})

const logout = () => {
  localStorage.clear()
  router.push('/')
}
</script>

<style scoped></style>
